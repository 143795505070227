import { useEffect, useState } from 'react';

import { useEncrypt } from '../../../hooks/useEncryptLink';
import { EventService } from '../../../Services/EventService';
import { BsCheckCircle } from 'react-icons/bs';
import { FormEmployee } from '../../../component/Funcionarios/CadastroPeloLink/FormEmployee';
import { Employee, LinkEmployeeRegister } from '../../../models/employeeType';
import { IFilterEmployee } from '../../../Interfaces/interfaces';
import { DialogInfo } from '../../../component/Funcionarios/CadastroPeloLink/Dialog/DialogInfo';
import { InfoEventCompany } from '../../../models/eventType';
import { TableEmployee } from '../../../component/Funcionarios/CadastroPeloLink/TableEmployee';
import { useFormat } from '../../../hooks/useFormat';
import { TypeLinkRegister } from '../../../enum/enums';
import { Paper } from '@mui/material';
import FormStepper from '../../../component/Funcionarios/CadastroPeloLink/FormStepperComponent';


interface IEvent {
    eventId: number,
    name: string,
    initialDate: Date | undefined,
    finalDate: Date | undefined,
}

const LinkCredenciamento = () => {
    const service = new EventService();

    const { decrypt } = useEncrypt();
    const [evento, setEvento] = useState<IEvent>();
    const [urlParams, setUrlParams] = useState<LinkEmployeeRegister>({} as LinkEmployeeRegister);
    const [employees, setEmployees] = useState<Employee[]>([]);
    const [infoEventCompany, setInfoEventCompany] = useState<InfoEventCompany>();
    const [finish, setFinish] = useState(false);

    if (urlParams.eventId === undefined) {

        //@ts-ignore
        let token = decodeURIComponent(window.location.href.split("/").pop());
        let response = decrypt(token);
        let urlParams: LinkEmployeeRegister = JSON.parse(response);
        setUrlParams(urlParams);
    }

    const filter: IFilterEmployee = {
        eventId: urlParams.eventId,
        fullName: '',
        cpf: '',
        companyId: urlParams.companyId
    }

    const fetchData = async () => {
        Promise.all([
            service.getEvent(urlParams.eventId),
            service.getEmployees(filter),
            service.getEventCompanyAsync(urlParams.eventId, urlParams.companyId)
        ]).then(([event, employees, infoEvento]) => {
            setEvento(event);
            setEmployees(employees);
            setInfoEventCompany(infoEvento);
        });
    }

    const reloadTable = async () => {
        await fetchEmployees();
    }

    const fetchEmployees = async () => {
        let employees = await service.getEmployees(filter);
        setEmployees(employees);
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            {!finish ? (
                <div className='p-0 flex mx-10 align-top mt-5'>
                    {infoEventCompany && (<DialogInfo
                        nameEvent={infoEventCompany.eventName}
                        qtdEmployees={infoEventCompany.maxEmployeeAllowd}
                        nameCompany={infoEventCompany.companyName}
                        photoIsRequired={infoEventCompany.photoIsRequired}
                        typeLink={urlParams.typeLink}
                    />
                    )}
                    {evento && urlParams && (
                        <div className='flex flex-col w-full gap-3'>
                            {urlParams.typeLink == TypeLinkRegister.LinkUnico && (
                                <FormStepper
                                    eventId={evento.eventId}
                                    companyId={urlParams.companyId}
                                    typeLink={urlParams.typeLink}
                                    reloadTable={reloadTable}
                                    setFinish={setFinish}
                                />
                            )}

                            {urlParams.typeLink == TypeLinkRegister.LinkCompleto && (
                                <TableEmployee
                                    employees={employees}
                                    companyName={urlParams.companyName}
                                    reloadTable={reloadTable}
                                />
                            )}
                        </div>
                    )}
                </div>
            ) : (
                <div className="flex justify-center items-center px-4 h-full" style={{
                    background: '#003958'
                }}>
                    <Paper elevation={10} className='bg-white container h-[400px] rounded flex justify-center items-center text-green-600' style={{
                        fontSize: '1.5rem',
                        padding: '20px',
                    }}>
                        CADASTRO FINALIZADO COM SUCESSO !
                        <BsCheckCircle style={{
                            marginLeft: '10px',
                            fontSize: '30px'
                        }} />
                    </Paper>
                </div>
            )}
        </>
    )
}

export default LinkCredenciamento;
