import { Form } from "react-bootstrap";
import { Autocomplete, Button, Collapse, Dialog, DialogActions, IconButton, Paper, TextField, Tooltip, styled } from "@mui/material";
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { AiFillFileExcel, AiOutlineLink } from "react-icons/ai";
import { BsFillTrashFill } from "react-icons/bs";
import { TfiReload } from "react-icons/tfi";
import { CiExport } from "react-icons/ci";
import XLSX from 'sheetjs-style';
import * as FileSaver from 'file-saver';
import { useEffect, useState } from "react";

import { MyButton } from "../MyButton";
import { CompanyService } from "../../Services/CompanyService";
import { Company, Event } from "../../models/eventType";
import { EnumQuantity, RoleEnum } from "../../models/enumType";
import { useEncrypt } from "../../hooks/useEncryptLink";
import { useFormat } from "../../hooks/useFormat";
import { EnableComponent } from "../EnableComponent";
import { EventService } from "../../Services/EventService";
import { ExportDataService } from "../../Services/ExportDataService";
import { ExcelHelper } from "../../Helpers/ExcelHelper";
import { TypeLinkRegister } from "../../enum/enums";
import { LinkEmployeeRegister } from "../../models/employeeType";
import { useConvert } from "../../hooks/useConvert";
import { toast } from "react-toastify";
import { showErrorValidation } from "../../hooks/validation";
import { IoIosWarning } from "react-icons/io";

type Props = {
    reloadPage: () => void;
    handleClose: () => void;
    show: boolean;
    eventId: number;
}

const formSchema = z.object({
    companySelect: z.string(),
    eventId: z.number().min(1, 'Selecione um evento'),
    qtdEmployees: z.coerce.number().min(1, 'Informe a quantidade de funcionários')
});

type formType = z.infer<typeof formSchema>

export function ModalRegistrarEmpresa(props: Props) {
    var service = new CompanyService();
    var eventService = new EventService();
    var exportDataService = new ExportDataService();

    const [companys, setCompanys] = useState<Company[]>([]);
    const [tableCompanys, setTableCompanys] = useState<Company[]>([]);
    const [numberRandom, setNumberRandom] = useState<number>(0);
    const [linkRegister, setLinkRegister] = useState<string>("");
    const [showDialogLink, setShowDialogLink] = useState(false);
    const [evento, setEvento] = useState<Event>();
    const [typeLink, setTypeLink] = useState<TypeLinkRegister>(TypeLinkRegister.LinkUnico);
    const [hasLink, setHasLink] = useState(false);
    const [companySelect, setCompanySelect] = useState<Company | null>(null);
    const [importFileName, setImportFileName] = useState("IMPORTAR FORNECEDORES");
    const [excelFile, setExcelFile] = useState(null);
    const [logs, setLogs] = useState(new Array<string>());
    const [showLogs, setShowLogs] = useState(false);

    const { encrypt } = useEncrypt();
    const { formatMaskCpfOrCnpj } = useFormat();
    const { excelToJson } = useConvert();

    const {
        handleSubmit,
        register,
        setValue,
        reset
    } = useForm<formType>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            eventId: props.eventId
        }
    });

    const generateRegisterLink = async (typeLink: TypeLinkRegister) => {
        setTypeLink(typeLink);
        setHasLink(false);
        setHasLink(true);

        var data: LinkEmployeeRegister = {
            eventId: props.eventId,
            companyName: companySelect?.name ?? '',
            companyId: companySelect?.companyId ?? 0,
            typeLink: typeLink
        }

        const encryptedMessage = encrypt(data);
        let link = `${process.env.REACT_APP_BASE_URL}credenciamento/${encodeURIComponent(encryptedMessage)}`;
        setLinkRegister(link);
    }

    const changeQuantity = async (eventId: number, companyId: number, option: EnumQuantity) => {
        let request = {
            eventId: eventId,
            companyId: companyId,
            option: option
        }
        await service.changeQtdEmployee(request);
        fetchData();
        props.reloadPage();
    }

    const columns: GridColDef[] = [
        { field: 'name', headerName: 'Nome do Fornecedor', flex: 1 },
        {
            field: 'cnpj',
            headerAlign: 'center',
            align: 'center',
            headerName: 'CNPJ',
            flex: 1,
            renderCell: (params) => {
                return (<>{formatMaskCpfOrCnpj(params.row.cnpj)}</>)
            }
        },
        {
            field: 'qtdEmployees',
            headerName: 'Qtd de Funcionários',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            renderCell: (params: any) => {
                var company: Company = params.row;

                return (
                    <>
                        <EnableComponent role={RoleEnum.GerarLinkCadastroFornecedor}>
                            <IconButton className="py-1 px-3"
                                onClick={() => changeQuantity(props.eventId, company.companyId, EnumQuantity.Sub)}>
                                -
                            </IconButton>
                        </EnableComponent>
                        <span className="mx-1">{params.row.qtdEmployees}</span>
                        <EnableComponent role={RoleEnum.GerarLinkCadastroFornecedor}>
                            <IconButton className="py-1 px-3"
                                onClick={() => changeQuantity(props.eventId, company.companyId, EnumQuantity.Add)}>
                                +
                            </IconButton>
                        </EnableComponent>
                    </>
                )
            }
        },
        {
            field: 'email',
            headerAlign: 'center',
            align: 'center',
            headerName: 'Email de Contato',
            flex: 1,
        },
        {
            field: 'acoes',
            headerName: 'Ações',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            renderCell: (params: any) => {
                return (
                    <div>
                        <EnableComponent role={RoleEnum.GerarLinkCadastroFornecedor}>
                            <IconButton style={{ color: '#003958' }} size="large" onClick={() => {
                                setShowDialogLink(true);
                                setCompanySelect(params.row)
                            }}>
                                <AiOutlineLink />
                            </IconButton>
                        </EnableComponent>
                        <EnableComponent role={RoleEnum.ExcluirEmpresa}>
                            <IconButton style={{ color: '#003958' }} size="large" onClick={() => {
                                removeCompany(params.row)
                            }}>
                                <BsFillTrashFill />
                            </IconButton>
                        </EnableComponent>
                    </div>
                )
            }
        },
    ];

    const removeCompany = async (data: any) => {
        await service.removeCompany(props.eventId, data.companyId);
        fetchData();
    }

    const handle = async (data: formType) => {

        let company = companys.find(p => p.name?.trim() === data.companySelect?.trim());

        let request = {
            companyId: company?.companyId,
            eventId: data.eventId,
            qtdEmployees: data.qtdEmployees
        }


        await service.registerCompanyInEvent(request);
        reset();
        setNumberRandom(value => value + 1);
        fetchData();
        props.reloadPage();
    }

    const fetchData = async () => {
        Promise.all([
            service.getAllCompanysActive(true),
            service.getEventCompanysAsync(props.eventId),
            eventService.getEvent(props.eventId)
        ]).then((response: any) => {
            setCompanys(response[0]);
            setTableCompanys(response[1]);
            setEvento(response[2]);
        });
    }

    useEffect(() => {
        fetchData();
    }, [props.eventId]);

    const closeLinkDialog = () => {
        setShowDialogLink(false);
        setHasLink(false);
    }

    const handleExcelData = async () => {
        let excelData = await exportDataService.readCompanyWithEmployeesAsync(props.eventId);
        ExcelHelper.download(
            ['Fornecedor', 'Qtd Cadastrada', 'Max Permitido'],
            excelData,
            'Fornecedores'
        );
    }

    type DropdownProps = {
        option: string
    }

    function DropdownComponent({ option }: DropdownProps) {
        return (<>
            {option.length > 30 ? (
                <>
                    <Tooltip placement="top" title={option}>
                        <span className="text-nowrap overflow-hidden text-ellipsis" style={{ width: '80%' }}>
                            {option}
                        </span>
                    </Tooltip>
                    <Button variant="contained" color="success" onClick={() => {
                        setValue('companySelect', option)
                    }} style={{
                        cursor: 'pointer'
                    }}>
                        Selecionar
                    </Button>
                </>
            ) : (
                <>
                    <span className="text-nowrap overflow-hidden text-ellipsis " style={{ width: '80%' }}>
                        {option}
                    </span>
                    <Button variant="contained" color="success" onClick={() => {
                        setValue('companySelect', option)
                    }} style={{
                        cursor: 'pointer'
                    }}>
                        Selecionar
                    </Button>
                </>
            )}
        </>)
    }

    const handleSubmitExcel = async (excelFile: any) => {
        const regex = /[^\d]/g;

        if (excelFile !== null) {
            setImportFileName(excelFile.name);

            let json = await excelToJson(excelFile);
            let companys: Company[] = [];
            let logs: string[] = [];

            companys = json.map((element: any, index: number) => {
                let company: Company = {
                    companyId: 0,
                    name: (element['Fornecedor'] ?? '').toString().trim(),
                    cnpj: (element['CNPJ/CPF'] ?? '').toString().trim(),
                    email: (element['Email de Contato'] ?? '').toString().trim(),
                    phone: (element['Telefone de Contato'] ?? '').toString().trim(),
                    qtdEmployees: Number((element['Quantidade de Funcionarios'] ?? '').toString().trim()),
                    active: true,
                    fornecedorDIMEP: Number(((element['Id Fornecedor DIMEP'] ?? 0).toString().trim())),
                    accessProfileDIMEP: Number(((element['Perfil Acesso DIMEP'] ?? 0).toString().trim()))
                };

                company.cnpj = company.cnpj.replace(regex, '');
                company.phone = company.phone.replace(regex, '');
                validateFields(company, index + 1, logs);

                return company;
            });
            console.table(logs)
            if (logs.length == 0)
                await importarPlanilha(companys);
            else
                setLogs(logs);
        }
    }
    const importarPlanilha = async (dados: Company[]) => {
        let id = toast.loading("Importando Fornecedores...")
        service.importarPlanilhaExcel(props.eventId, dados)
            .then(response => {
                if (response.data.length === 0) {
                    fetchData();
                    toast.update(id, { render: 'Fornecedores importados com sucesso', type: 'success', isLoading: false, autoClose: 4000 })
                }
                else {
                    toast.update(id, { render: 'Não foi possivel importar a planilha, veja os logs para saber oque houve', type: 'success', isLoading: false, autoClose: 4000 });
                    setLogs(response.data);
                    setShowLogs(true);
                }
            }).catch(error => {
                showErrorValidation(error)
            }).finally(() => setImportFileName("IMPORTAR FORNECEDORES"));
    }

    const validateFields = (company: Company, line: number, logs: string[]) => {
        if (company.name != undefined && company.name == '')
            logs.push(`Linha[${line}]: Nome é obrigatório`);
        // if (company.cnpj != undefined && company.cnpj == '')
        //     logs.push(`Linha[${line}]: Nome é obrigatório`);
        // if (company.phone != undefined && company.phone == '')
        //     logs.push(`Linha[${line}]: Nome é obrigatório`);
        if (company.email != undefined && company.email == '')
            logs.push(`Linha[${line}]: Email é obrigatório`);
    }

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const downloadExcel = async () => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';

        const planilha = XLSX.utils.json_to_sheet([{
            'Fornecedor': '',
            'CNPJ/CPF': '',
            'Email de Contato': '',
            'Telefone de Contato': '',
            'Quantidade de Funcionarios': 0,
            'Id Fornecedor DIMEP': 0,
            'Perfil Acesso DIMEP': 0
        }]);

        const wb = { Sheets: { 'data': planilha }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, "Planilha de Fornecedores" + fileExtension);
    }

    function DialogLinkCadastro() {
        return (
            <Dialog maxWidth={'lg'}
                open={showDialogLink}
                onClose={() => setShowDialogLink(false)}
            >
                <div className="flex justify-center items-center gap-12 my-4 px-12">
                    <Button className="text-nowrap" fullWidth variant="contained" style={{ backgroundColor: '#003958' }} onClick={() => {
                        generateRegisterLink(TypeLinkRegister.LinkCompleto);
                    }}>
                        Gerar Link de Acesso Completo
                        <TfiReload className="text-xl ml-2" color="white" />
                    </Button>

                    <Button fullWidth variant="contained" style={{ backgroundColor: '#003958' }} onClick={() => {
                        generateRegisterLink(TypeLinkRegister.LinkUnico);
                    }}>
                        Gerar Link de Cadastro Unico
                        <TfiReload className="text-xl ml-2" color="white" />
                    </Button>
                </div>


                <div style={{ width: '100%' }}>
                    <Collapse in={hasLink} style={{ width: '100%' }}>
                        <Paper elevation={10} className='d-flex justify-content-center px-4 py-4 mx-5 rounded'>
                            <span style={{ inlineSize: '100%', overflowWrap: 'break-word' }}>
                                {linkRegister}
                            </span>
                        </Paper>
                    </Collapse>
                </div>

                <DialogActions>
                    <MyButton onClick={closeLinkDialog}>
                        Fecher Janela
                    </MyButton>
                </DialogActions>
            </Dialog>
        )
    }

    function DialogLogsImportacao() {
        return (
            <Dialog open={showLogs} onClose={() => setShowLogs(false)}>
                <div className="flex justify-center overflow-y-auto" style={{ maxHeight: '450px' }}>
                    <ul className='py-3'>
                        {logs.map((log, index) => {
                            return (
                                <li key={index} style={{ borderBottom: '0.5px solid #ccc', marginBottom: '5px', listStyle: 'none' }}>
                                    <div className='flex justify-start items-center gap-2'>
                                        <IoIosWarning className='icon-warning' />
                                        <label className="pr-4">{log}</label>
                                    </div>
                                </li>
                            )
                        })
                        }
                    </ul>
                </div>
                <DialogActions>
                    <MyButton onClick={() => setShowLogs(false)}>
                        Fecher Janela
                    </MyButton>
                </DialogActions>
            </Dialog>
        )
    }

    return (
        <Dialog
            fullWidth={true}
            fullScreen
            open={props.show}
            onClose={props.handleClose}
            style={{
                overflow: 'hidden'
            }}
        >
            <header className="header">
                <p className="text-white text-center py-2 text-2xl">
                    ADICIONE FORNECEDORES AO EVENTO
                </p>
            </header>

            <EnableComponent role={RoleEnum.CadastrarEditarEmpresa}>
                <Form onSubmit={handleSubmit(handle)} className="row flex m-0 my-4 px-5 border-0 w-full">
                    <div className="col-md-4">
                        <Autocomplete
                            key={numberRandom}
                            disablePortal
                            id="combo-box-demo"
                            options={companys.map((p: Company) => `${p.name}`)}
                            fullWidth
                            onInputChange={(event: any, value: any) => {
                                setValue('companySelect', value);
                            }}
                            renderOption={(props, option, { selected }) => {
                                return (<li {...props}>
                                    <section className="flex justify-between items-center w-full" >
                                        <DropdownComponent option={option} />
                                    </section>
                                </li>
                                )
                            }}
                            renderInput={(params) => <TextField {...params} label="Pesquise um fornecedor ja Cadastrado" />}
                        />
                    </div>
                    <div className="col-md-4">
                        <TextField label="Qtd de Funcionários para Esse Evento"
                            type="number"
                            {...register('qtdEmployees')}
                            fullWidth
                        />
                    </div>

                    <div className="col-md-4">
                        <MyButton endIcon={<AddBusinessIcon />}
                            type="submit"
                            className="h-full">
                            Adicionar
                        </MyButton>
                    </div>
                </Form>
                <div className="px-5 m-0 flex gap-4">
                    <MyButton endIcon={<AiFillFileExcel />}
                        onClick={downloadExcel}
                    >
                        BAIXAR PLANILHA PADRÃO
                    </MyButton>
                    <Form style={{ border: '0px', padding: '5px 0px' }} className="w-full p-0 m-0">
                        <Form.Group controlId="formFile">
                            <Button component="label"
                                variant="contained"
                                fullWidth
                                endIcon={<CiExport />}
                                onChange={(event: any) => {
                                    handleSubmitExcel(event.target.files[0])
                                }}
                                style={{ background: '#003958', color: 'white', width: '100%' }}>
                                {importFileName}
                                <VisuallyHiddenInput type="file" />
                            </Button>
                        </Form.Group>
                    </Form>
                    <MyButton endIcon={<CiExport className="text-2xl" />} onClick={handleExcelData}>Exportar Fornecedores</MyButton>
                </div>
            </EnableComponent>

            <div id="cadastro" className="row m-0 my-3 px-5">
                <header className="py-3 header" style={{
                    borderRadius: '5px 5px 0px 0px'
                }}>
                    LISTA DE FORNECEDORES DO EVENTO {evento?.name.toLocaleUpperCase()}
                </header>
                
                <div style={{ height: '450px' }} className="p-0">
                    <DataGrid
                        rows={tableCompanys}
                        getRowId={(row) => row.companyId}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    page: 0,
                                    pageSize: 10
                                },
                            },
                        }}
                        pageSizeOptions={[10]}
                    />
                </div>

                <div className="my-4 px-5">
                    <MyButton onClick={() => {
                        props.handleClose();
                    }} autoFocus>
                        Fecher Janela
                    </MyButton>
                </div>
            </div>

            <DialogLinkCadastro />

            <DialogLogsImportacao />
        </Dialog >
    )
}