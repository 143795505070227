import { Avatar, Button, Dialog, IconButton, Paper, Skeleton, Tooltip } from "@mui/material"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { useEffect, useState } from "react";
import { useMediaQuery } from 'react-responsive';

import { useFormat } from "../../../hooks/useFormat";
import { BsFillTrashFill } from "react-icons/bs";
import { EventService } from "../../../Services/EventService";
import { Employee } from "../../../models/employeeType";
import { FaAddressCard } from "react-icons/fa";
import styles from '../../../Pages/Funcionarios/CadastroPeloLink/cadastro.module.css';

interface IProps {
    employees: Employee[];
    companyName: string;
    reloadTable: Function;
}


interface ICardProps {
    employee: Employee;
    index: number;
}


export function TableEmployee(props: IProps) {

    const service = new EventService();
    const { formatMaskCpfOrCnpj, formatMaskRgOrCpf } = useFormat();
    const isMobileQuery = useMediaQuery({ query: '(max-width: 768px)' });
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [employeeSelect, setEmployeeSelect] = useState<Employee | null>(null);
    const [showDialog, setShowDialog] = useState<boolean>(false);

    useEffect(() => {
        setIsMobile(isMobileQuery);
    }, [isMobileQuery]);

    const columns: GridColDef[] =
        [
            {
                field: 'photoUri', headerName: 'Foto', headerAlign: 'center', align: 'center',
                renderCell: (params: any) => {
                    return (
                        <Avatar
                            onClick={() => {
                                setEmployeeSelect(params.row);
                                setShowDialog(true);
                            }}
                            alt="Remy Sharp"
                            src={`${params.value}?v=${Math.random()}`}>
                        </Avatar>
                    )
                }
            },
            {
                field: 'fullName',
                headerName: 'Nome do Funcionario',
                flex: 2,
            },
            {
                field: 'function',
                headerName: 'Função',
                align: 'center',
                headerAlign: 'center',
                flex: 1,
                renderCell: (params: any) => <span>{params.value}</span>
            },
            {
                field: 'cpf',
                headerName: 'CPF',
                align: 'center',
                headerAlign: 'center',
                flex: 1,
                renderCell: (params: any) => {
                    return (<span>{formatMaskCpfOrCnpj(params.value)}</span>)
                },
            },
            {
                field: 'company',
                headerName: 'Empresa',
                align: 'center',
                headerAlign: 'center',
                flex: 1,
                renderCell: (params: any) => {
                    return (<span>{props.companyName}</span>)
                },
            },
            {
                field: 'staff',
                headerName: 'Setor',
                align: 'center',
                headerAlign: 'center',
                flex: 1,
                renderCell: (params: any) => <span>{params.value}</span>
            },
            {
                field: 'actions',
                headerName: 'Ações',
                align: 'center',
                headerAlign: 'center',
                flex: 1,
                filterable: false,
                renderCell: (params: any) => {
                    return (
                        <div>
                            <Tooltip title='Exibir Funcionário'>
                                <IconButton size="large" onClick={() => {
                                    setEmployeeSelect(params.row);
                                    setShowDialog(true);
                                }}>
                                    <FaAddressCard style={{ color: '#003958' }} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title='Excluir Funcionário' placement="top">
                                <IconButton style={{ color: 'red' }} size="large" onClick={() => handleDelete(params.row)}>
                                    <BsFillTrashFill />
                                </IconButton>
                            </Tooltip>
                        </div>
                    );

                }
            }
        ]

    const handleDelete = async (row: any) => {
        if (row.employeeId > 0) {
            await service.deleteEmployee(row.employeeId);
            props.reloadTable();
        }
    }

    return (
        <div>
            <DialogInfo />
            {isMobile ? (
                <>
                    {props.employees.length > 0 && (
                        <Paper elevation={8}>
                            <header className='py-3 mb-3 header' style={{
                                borderRadius: '5px 5px 0px 0px'
                            }}>
                                {"FUNCIONÁRIOS JA CADASTRADOS"}
                            </header>
                            {
                                props.employees.map((value, index) => (
                                    <ItemCard employee={value} index={index} />
                                ))
                            }
                        </Paper>
                    )}
                </>
            ) : (
                <>
                    {props.employees.length > 0 ? (
                        <Paper elevation={10}>
                            <DataGrid
                                columns={columns}
                                rows={props.employees}
                                getRowId={(row: any) => row.employeeId}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            page: 0,
                                            pageSize: 10
                                        },
                                    },
                                }}
                                pageSizeOptions={[10]}
                            />
                        </Paper>
                    ) : (
                        <Paper elevation={10} className="text-center p-5 text-2xl">
                            Nenhum Funcionário Cadastrado
                        </Paper>
                    )}
                </>
            )}

        </div>
    )

    function ItemCard(item: ICardProps) {
        return (
            <Paper elevation={4} key={item.index} className="my-2 mx-2 pt-2">
                <div className="row">
                    <div className="flex items-center justify-start ml-5 gap-3 pt-3">
                        <Avatar
                            alt="Remy Sharp"
                            src={`${item.employee.photoUri}?v=${Math.random()}`}>
                        </Avatar>
                        <p className="m-0 overflow-hidden whitespace-nowrap text-ellipsis" style={{ width: '70%' }}>{item.employee.fullName}</p>
                    </div>
                    <div className="row m-0 py-3 flex justify-center">
                        <hr style={{ width: '90%' }} />
                        <div className="flex justify-center items-start gap-2">
                            <Button variant="text" className="flex" fullWidth onClick={() => {
                                setShowDialog(true);
                                setEmployeeSelect(item.employee);
                            }}>
                                Exibir
                            </Button>
                            <Button variant="text" color="error" fullWidth onClick={() => handleDelete(item.employee)}>Excluir</Button>
                        </div>
                    </div>
                </div>
            </Paper>
        )
    }

    function DialogInfo() {
        return (
            <Dialog open={showDialog} style={{ overflow: 'visible !important' }}>
                <div className="py-3 header">
                    DADOS CADASTRAIS DO FUNCIONÁRIO
                </div>
                <div className="px-4 py-2 flex-col items-center justify-center">
                    <section>
                        <div className="flex items-center justify-center gap-3 flex-col">
                            <p className="p-0 m-0 font-semibold text-xl text-center">{employeeSelect?.fullName}</p>
                            {/* <Skeleton className="h-[300px] w-[300px]"> */}
                                <img src={`${employeeSelect?.photoUri}?v=${Math.random()}`}
                                    className="rounded-xl p-0 m-0 w-full h-auto object-cover max-h-[300px] max-w-[300px]" />
                            {/* </Skeleton> */}
                        </div>
                        <hr style={{ width: '100%' }} />
                        <div className={styles.infoDialog}>
                            <p className="font-semibold m-0">CPF/RG:</p>
                            <p className="m-0">{formatMaskRgOrCpf(employeeSelect?.cpf ?? "")}</p>
                        </div>
                        <hr style={{ width: '100%' }} />
                        <div className={styles.infoDialog}>
                            <p className="font-semibold m-0">Empresa:</p>
                            <p className="m-0">{props.companyName}</p>
                        </div>
                        <hr style={{ width: '100%' }} />
                        <div className={styles.infoDialog}>
                            <p className="font-semibold m-0">Função:</p>
                            <p className="m-0">{employeeSelect?.function}</p>
                        </div>
                        <hr style={{ width: '100%' }} />
                        <div className={styles.infoDialog}>
                            <p className="font-semibold m-0">Setor:</p>
                            <p className="m-0">{employeeSelect?.staff}</p>
                        </div>
                        <hr style={{ width: '100%' }} />
                    </section>

                    <section className="row m-0">
                        <Button variant="contained" onClick={() => setShowDialog(false)} style={{ backgroundColor: '#003958' }}>
                            FECHAR
                        </Button>
                    </section>
                </div>
            </Dialog>
        )
    }
}